// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { BlockComponent } from "framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
const navigation = require("react-navigation")
// Customizable Area End

export interface Props {
  // Customizable Area Start
  t:(value:string)=> string;
  navigation :typeof navigation
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  anchor: null | HTMLElement;
  deleteContractModal: boolean;
  contractDetails: ContractDetailsType;
  showError:boolean;
  error:string;
  constractId: string;
  showSuccessMessage: boolean;
  successAlertMessage: string;
  // Customizable Area End
}

// Customizable Area Start
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
};
export interface ContractDetailsType {
  id: string;
  type: string;
  attributes: {
    account_id: number;
    apartment_management_id: number;
    society_management_id: number;
    building_management_id: number;
    country: string | null;
    landlord_name: string;
    city: string | null;
    region: string | null;
    agreement_duration: string;
    currency: string;
    start_date: string;
    state: string | null;
    expires_on: string;
    duration: string | null;
    tenant_name: string;
    rent_amount: number;
    status: string;
    lease_template_id: number;
    custom_contract: boolean;
    penanlty_late_payment: boolean;
    created_at: string;
    contract_template: string;
    updated_at: string;
    owner_name: string | null;
    owner: {
      first_name: string | null;
      id: number;
      full_phone_number: string;
      last_name: string | null;
      phone_number: number | null;
      country_code: string | null;
      activated: boolean;
      email: string;
      unique_auth_id: string | null;
      device_id: string | null;
      updated_at: string;
      created_at: string;
      platform: string | null;
      user_name: string | null;
      app_language_id: number | null;
      last_visit_at: string | null;
      user_type: string | null;
      suspend_until: string | null;
      is_blacklisted: boolean;
      stripe_id: string | null;
      status: string;
      stripe_subscription_date: string | null;
      stripe_subscription_id: string | null;
      full_name: string;
      gender: string | null;
      role_id: number | null;
      age: number | null;
      date_of_birth: string | null;
      company_name: string | null;
      is_paid: boolean;
      owner_full_name: string | null;
      owner_email: string | null;
      manager_full_name: string | null;
      public_field: string | null;
      owner_phone_number: string | null;
      building_management_id: number | null;
      disable_chat: boolean;
      language_type: string | null;
      ticket_days_configuration: number;
    };
    contract_type: string;
    lease_template_text: string;
    building_name: string;
    expire_on: number;
    lease_template_pdf: {
      url: string;
    };
    contract_template_pdf: {
      url: string;
    };

    society_management: {
      name: string;
      id: number;
      description: string;
      updated_at: string;
      created_at: string;
      maintenance_per_square_feet: number;
      complex_area: string;
      ticket_days_configuration: number;
      measurement_unit: string;
      zone_multiplier: number;
      contigency: number;
      late_charge_percentage: string;
      longitude: string | null;
      latitude: string | null;
      is_building: boolean;
      value_added_tax: string;
      total_floor: number | null;
      real_estate_company_id: number | null;
      currency_id: number | null;
      total_unit: number | null;
      language: string | null;
      status: string | null;
      account_manager_id: number | null;
    };
    terms: null;
    unit_name: string;
    custom_term_condition: null;
    custom_term_condition_added: boolean;
    conditions: null;
    tenant: {
      first_name: string | null;
      id: number;
      full_phone_number: string;
      last_name: string | null;
      phone_number: number | null;
      country_code: string | null;
      activated: boolean;
      email: string;
      unique_auth_id: string | null;
      device_id: string | null;
      created_at: string;
      updated_at: string;
      platform: string | null;
      user_name: string | null;
      app_language_id: number | null;
      user_type: string | null;
      is_blacklisted: boolean;
      last_visit_at: string | null;
      status: string;
      stripe_id: string | null;
      suspend_until: string | null;
      stripe_subscription_date: string | null;
      stripe_subscription_id: string | null;
      full_name: string;
      role_id: number | null;
      date_of_birth: string | null;
      gender: string | null;
      is_paid: boolean;
      age: number | null;
      manager_full_name: string | null;
      company_name: string | null;
      owner_email: string | null;
      owner_full_name: string | null;
      public_field: string | null;
      owner_phone_number: string | null;
      building_management_id: number | null;
      disable_chat: boolean;
      ticket_days_configuration: number;
      language_type: string | null;
    };
    penalty_late_payments: {
      id: number;
      amount: string;
      penanlty_counted: string;
      penanlty_type: string | null;
      tenant_id: number;
      account_id: number;
    };
    custom_contract_image: string | null;
  };
};

export interface DraftContractDetailsSuccessResponse {
  contract: {
    data: ContractDetailsType
  };
  code: number;
  message: string;
};

export interface ApiFailureResponse {
  errors: Array<ApiFailureResponseError>
};

export interface ApiFailureResponseError {
  contract: string
};

interface HasSuccessErrorType {
  isOpen: boolean,
  isSeverity: "success" | "info" | "warning" | "error",
  isMessage: string,
};

export type TFunction = (value: string) => string;

interface DeleteDraftResponse {
  code: number,
  message: string
};

// Customizable Area End

interface SS {
  id: string;
}

export default class LeaseManagementDraftContractDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  draftContractDetailsApiCallId: string = "";
  deleteContractApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      anchor: null,
      deleteContractModal: false,
      showError:false,
      error:"",
      contractDetails: {
            "type": "",
            "id": "",
            "attributes": {
              "account_id": 0,
              "apartment_management_id": 0,
              "society_management_id": 0,
              "building_management_id": 0,
              "country": null,
              "landlord_name": "",
              "city": null,
              "region": null,
              "start_date": "",
              "agreement_duration": "",
              "currency": "",
              "rent_amount": 0,
              "state": null,
              "expires_on": "",
              "duration": null,
              "tenant_name": "",
              "status": "",
              "penanlty_late_payment": true,
              "lease_template_id": 1,
              "contract_template": "",
              "custom_contract": false,
              "updated_at": "",
              "owner_name": null,
              "created_at": "",
              "owner": {
                "first_name": null,
                "id": 25,
                "full_phone_number": "",
                "last_name": null,
                "email": "",
                "phone_number": 0,
                "country_code": null,
                "activated": true,
                "device_id": null,
                "updated_at": "",
                "unique_auth_id": null,
                "suspend_until": null,
                "status": "regular",
                "stripe_id": null,
                "stripe_subscription_id": null,
                "stripe_subscription_date": null,
                "gender": null,
                "role_id": null,
                "platform": null,
                "created_at": "",
                "user_name": null,
                "full_name": "",
                "company_name": null,
                "is_paid": false,
                "owner_phone_number": null,
                "manager_full_name": null,
                "app_language_id": null,
                "date_of_birth": null,
                "owner_full_name": null,
                "user_type": null,
                "last_visit_at": null,
                "is_blacklisted": false,
                "age": null,
                "disable_chat": false,
                "ticket_days_configuration": 0,
                "owner_email": null,
                "building_management_id": null,
                "language_type": null,
                "public_field": null,
              },
              "contract_template_pdf": {
                "url": ""
              },
              "lease_template_pdf": {
                "url": ""
              },
              "contract_type": "",
              "lease_template_text": "",
              "building_name": "",
              "expire_on": 0,
              "society_management": {
                "id": 31,
                "description": "",
                "updated_at": "",
                "created_at": "",
                "name": "",
                "complex_area": "",
                "maintenance_per_square_feet": 0,
                "measurement_unit": "",
                "zone_multiplier": 0,
                "contigency": 0,
                "ticket_days_configuration": 0,
                "late_charge_percentage": "",
                "value_added_tax": "",
                "longitude": null,
                "latitude": null,
                "is_building": false,
                "real_estate_company_id": null,
                "total_unit": null,
                "total_floor": null,
                "currency_id": null,
                "account_manager_id": null,
                "language": null,
                "status": null,
              },
              "terms": null,
              "unit_name": "A-202",
              "conditions": null,
              "custom_term_condition": null,
              "custom_term_condition_added": false,
              "tenant": {
                "updated_at": "",
                "user_type": null,
                "created_at": "",
                "user_name": null,
                "platform": null,
                "last_visit_at": null,
                "phone_number": null,
                "country_code": null,
                "activated": true,
                "email": "",
                "unique_auth_id": null,
                "device_id": null,
                "first_name": null,
                "id": 26,
                "app_language_id": null,
                "suspend_until": null,
                "full_phone_number": "",
                "stripe_subscription_date": null,
                "stripe_subscription_id": null,
                "last_name": null,
                "is_blacklisted": false,
                "stripe_id": null,
                "status": "regular",
                "disable_chat": false,
                "ticket_days_configuration": 0,
                "full_name": "freny",
                "manager_full_name": null,
                "company_name": null,
                "owner_email": null,
                "role_id": null,
                "date_of_birth": null,
                "gender": null,
                "public_field": null,
                "owner_phone_number": null,
                "is_paid": false,
                "age": null,
                "owner_full_name": null,
                "language_type": null,
                "building_management_id": null,
              },
              "penalty_late_payments": {
                "id": 1,
                "penanlty_counted": "",
                "penanlty_type": null,
                "amount": "10",
                "account_id": 25,
                "tenant_id": 26
              },
              "custom_contract_image": null
        },

      },
      constractId: "1",
      showSuccessMessage: false,
      successAlertMessage: ""
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.draftContractDetailsApiResponseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.draftContractDetailsApiResponseFailureCall(apiRequestCallId, responseJson);
      }
    }else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let createContractData = message.getData(getName(MessageEnum.DraftDetailsId));
       if(createContractData){
        this.setState({constractId:createContractData},()=>{
          this.getDraftContractDetails();
        })
       }
    }
  };

  draftContractDetailsApiCall = async (data: APIPayloadType) => {
    let token = localStorage.getItem("loginSuccessToken")
    let { method, endPoint, body, type = "", contentType } = data;
    const header = {
      token: token,
      "Content-Type": contentType,
    };
    let draftContractDetailsMainrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    draftContractDetailsMainrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    draftContractDetailsMainrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    draftContractDetailsMainrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    draftContractDetailsMainrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body && type !== "formData"
      ? draftContractDetailsMainrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : draftContractDetailsMainrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(draftContractDetailsMainrequestMessage.id, draftContractDetailsMainrequestMessage);
    return draftContractDetailsMainrequestMessage.messageId;
  };



  draftContractDetailsApiResponseSucessCell = async (
    apiRequestCallId: string,
    responseJson: DraftContractDetailsSuccessResponse) => {
    if (apiRequestCallId === this.draftContractDetailsApiCallId) {
      this.contractDetailsSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteContractApiCallId) {
      this.deleteContractSucessCallBack(responseJson);
    }
  };

  draftContractDetailsApiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
    if (apiRequestCallId === this.draftContractDetailsApiCallId) {
      this.contractDetailsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteContractApiCallId) {
      this.deleteContractDetailsFailureCallBack(responseJson);
    }
  };



  getDraftContractDetails = async () => {
    this.draftContractDetailsApiCallId = await this.draftContractDetailsApiCall({
      method: configJSON.draftContractDetailsMethod,
      endPoint: `${configJSON.draftContractDetailstEndPoint}${this.state.constractId}`,
    });
  };

  contractDetailsSucessCallBack = (response: DraftContractDetailsSuccessResponse) => {
        this.setState({ contractDetails: response.contract.data })
  };

  contractDetailsFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract });
  };

  deleteDraftContractDetails = async () => {
    this.deleteContractApiCallId = await this.draftContractDetailsApiCall({
      method: configJSON.deleteDraftContractDetailsMethod,
      endPoint: `${configJSON.deleteDraftEndPoint}${this.state.constractId}`,
    });
  };

  deleteContractSucessCallBack = (response: DeleteDraftResponse) => {
    this.setState({ showSuccessMessage: true, successAlertMessage: response.message })
  };

  deleteContractDetailsFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract});
  };

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchor: event?.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchor: null,
    });
  };

  handleOpenTerminateContractModal = () => {
    this.setState({ deleteContractModal: true });
  };

  handleCloseTerminateContractModal = () => {
    this.setState({ deleteContractModal: false });
  };

  handleCloseAlertError = () => { this.setState({ showError: false, error: "" }); };

  handleDownloadFile = (fileUrl : string) =>{
    const downloadPdfBtn = document.createElement('a');
    downloadPdfBtn.href = fileUrl;
    downloadPdfBtn.download = 'document.pdf';
    downloadPdfBtn.target = '_blank';
    document.body.appendChild(downloadPdfBtn);
    downloadPdfBtn.click();
    document.body.removeChild(downloadPdfBtn);
  };

  handleExistRecordNavigation = (path: string) => {
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
    this.send(msgData);
  };

  handleUseDraft = () => {
    let createContractPayload = {
      templateId: this.state.contractDetails.attributes.lease_template_id,
      country: this.state.contractDetails.attributes.country,
      city: this.state.contractDetails.attributes.city,
      complex: this.state.contractDetails.attributes.society_management.is_building ? "Individual Building":"Complex",
      buildingName: this.state.contractDetails.attributes.building_name,
      unitName: this.state.contractDetails.attributes.unit_name,
      complexName: this.state.contractDetails.attributes.society_management.name,
      tenant_name: this.state.contractDetails.attributes.tenant.full_name,
      landlord_name: this.state.contractDetails.attributes.owner.full_name,
      building_management_id: this.state.contractDetails.attributes.building_management_id,
      society_management_id: this.state.contractDetails.attributes.society_management_id,
      tenant_id: this.state.contractDetails.attributes.tenant.id,
      expires_on: this.state.contractDetails.attributes.expire_on,
      apartment_management_id: this.state.contractDetails.attributes.apartment_management_id,
      agreement_duration: this.state.contractDetails.attributes.agreement_duration,
      start_date: this.state.contractDetails.attributes.start_date,
      term_ids: this.state.contractDetails.attributes.terms,
      lease_template_id: this.state.contractDetails.attributes.lease_template_id,
      rent_amount: this.state.contractDetails.attributes.rent_amount,
      currency: this.state.contractDetails.attributes.currency,
      condition_ids: this.state.contractDetails.attributes.conditions,
      custom_contract: this.state.contractDetails.attributes.custom_contract,
      penanlty_late_payment: this.state.contractDetails.attributes.penanlty_late_payment,
      contract_template: this.state.contractDetails.attributes.contract_template,
      owner: this.state.contractDetails.attributes.owner.full_name,
      ownerNumber: this.state.contractDetails.attributes.owner.phone_number,
      ownerEmail: this.state.contractDetails.attributes.owner.email,
      accountId: this.state.contractDetails.attributes.owner.id,
      contractLink: this.state.contractDetails.attributes.contract_template_pdf.url
    };
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "IssueNewContractReview");
    msgData.addData(getName(MessageEnum.CreateContractPayloadData), createContractPayload);
    this.send(msgData);
  };

  handleCloseSuccessAlert = () => {
    this.setState({showSuccessMessage:false},()=>{
      this.handleExistRecordNavigation("LeaseManagment")
    });
  };
  // Customizable Area End
}
