// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { MouseEvent } from "react";
import { setStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");
const navigation = require("react-navigation");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    t:(value:string)=> string;
    navigation:typeof navigation
    // Customizable Area End
}
interface S {
    // Customizable Area Start
            tabItem: string;
    complex: string;
    country: string;
    city: string;
    selectComplex: string;
    company:string;
    building: string;
    unitNumber: string;
    contractStatus: string;
    owner: string;
    contractIssuer: string;
    contractListData: Array<ContractListItem>;
    contractSearchText: string;
    savedTemplatesListData: Array<SavedTemplateItem>;
    savedTemplateSearchText: string;
    draftListData: Array<DraftListItem>;
    draftSearchText: string;
    anchorEl: HTMLElement | null;
    openPopoverIndex: number | null;
    contractListPage: number;
    savedTemnplatePage: number;
    draftListPage: number;
    countryList: Array<string>;
    cityList: Array<string>;
    buildingList: Array<SingleBuilding>;
    complexList: Array<SingleComplex>;
    unitNumberList: Array<UnitNumberinterface>;
    contractListTotalItem: number;
    contractListTotalPage: number;
    savedTemplatesTotalItem: number;
    savedTemplatesTotalPage: number;
    draftListTotalItem: number;
    draftListTotalPage: number;
    selectComplexList: Array<GetSelectComplexListResponseItem>;
    ownerList: Array<GetOwnerListSuccessResponseItem>;
    companyList: Array<CompanyListSuccessResponseItem>;
    showError: boolean;
    error: string;
    loader: boolean;
    societyMagementId: string;
    buildingManagementId: string;
    unitId: string;
    openBuildingModal: boolean;
    contractSortBy: string;
    deleteDraftModal: boolean;
    deleteDraftContractId: string;
    showSuccessMessage: boolean;
    successAlertMessage: string;
    latitude: LatitudeLongitude;
    longitude: LatitudeLongitude;
    contractPaginationData: PaginationDataInterface;
    draftPaginationData: PaginationDataInterface;
    savedTemplatePaginationData: PaginationDataInterface;
        // Customizable Area End
}

// Customizable Area Start
export type TFunction = (value: string) => string;

export interface PaginationDataInterface {
    current_page: null | number;
    next_page: null | number;
    prev_page: null | number;
    total_pages: null | number;
    total_count: null | number;
}

export type LatitudeLongitude = null | string | number | undefined;

export interface ContractListOwnerItem{
    id: number,
    full_phone_number: string,
    last_name: null,
    first_name: null,
    email: string,
    activated: boolean,
    country_code: null,
    phone_number: number,
    device_id: null,
    created_at: string,
    password_digest: string,
    unique_auth_id: null,
    updated_at: string,
    user_type: null,
    user_name: null,
    last_visit_at: null,
    full_name: string,
    app_language_id: null,
    is_paid: boolean,
    owner_full_name: null,
    manager_full_name: null,
    owner_phone_number: null,
    company_name: null,
    is_blacklisted: boolean,
    platform: null,
    status: string,
    suspend_until: null,
    public_field: null,
    stripe_subscription_id: null,
    stripe_id: null,
    role_id: null,
    owner_email: null,
    gender: null,
    date_of_birth: null,
    age: null,
    disable_chat: boolean,
    building_management_id: null,
    stripe_subscription_date: null,
    language_type: null,
    ticket_days_configuration: number
}

export interface SocietyManagement {
    name: string;
    id: number;
    created_at: string;
    updated_at: string;
    complex_area: string;
    contigency: number;
    maintenance_per_square_feet: number;
    description: string;
    measurement_unit: string;
    ticket_days_configuration: number;
    zone_multiplier: number;
    late_charge_percentage: string;
    latitude: LatitudeLongitude
    longitude: LatitudeLongitude
    value_added_tax: string;
    is_building: boolean;
    real_estate_company_id: null;
    total_floor: null;
    total_unit: null;
    status: null
    account_manager_id: null;
    language: null;
    currency_id: null;
};

export interface ContractListItem {
    type: string,
    id: string,
    attributes: {
        account_id: number,
        apartment_management_id: number,
        society_management_id: number,
        landlord_name: string,
        country: null,
        region: null,
        building_management_id: number,
        agreement_duration: string,
        start_date: string,
        city: null,
        currency: string,
        expires_on: string,
        state: null,
        penanlty_late_payment: boolean,
        tenant_name: string,
        rent_amount: number,
        lease_template_id: number,
        status: string,
        duration: null,
        custom_contract: boolean,
        contract_template: string,
        owner_name: null,
        updated_at: string,
        created_at: string,
        owner: ContractListOwnerItem | null,
        lease_template_pdf: {
            url: string
        },
        contract_template_pdf: {
            url: string
        },

        building_name: string,
        contract_type: string,
        lease_template_text: string,
        expire_on: number,
        society_management: SocietyManagement | null,
        terms: null,
        custom_term_condition_added: boolean,
        conditions: null,
        unit_name: string,
        custom_term_condition: null,
        tenant: {
            id: number,
            full_phone_number: string,
            last_name: null,
            first_name: null,
            country_code: null,
            suspend_until: null,
            is_blacklisted: boolean,
            stripe_id: null,
            status: string,
            stripe_subscription_date: null,
            phone_number: null,
            full_name: string,
            role_id: null,
            date_of_birth: null,
            gender: null,
            is_paid: boolean,
            age: null,
            activated: boolean,
            email: string,
            stripe_subscription_id: null,
            owner_full_name: null,
            password_digest: string,
            created_at: string,
            device_id: null,
            user_name: null,
            updated_at: string,
            user_type: null,
            company_name: null,
            platform: null,
            unique_auth_id: null,
            owner_email: null,
            manager_full_name: null,
            owner_phone_number: null,
            app_language_id: null,
            building_management_id: null,
            disable_chat: boolean,
            public_field: null,
            language_type: null,
            ticket_days_configuration: number
            last_visit_at: null,
        } | null;
        custom_contract_image: null,
        penanlty_late_payments: {
            penanlty_counted: string,
            penanlty_type: null,
            tenant_id: number
            amount: string,
            account_id: number,
            id: number,
        } | null;
        lease_issuer: Array<string>;
    }
};
export interface DraftListItem {
    type: string,
    id: string,
    attributes: {
        account_id: number,
        building_management_id: number,
        society_management_id: number,
        apartment_management_id: number,
        country: null,
        region: null,
        city: null,
        landlord_name: string,
        start_date: string,
        rent_amount: number,
        duration: null,
        currency: string,
        expires_on: string,
        state: null,
        tenant_name: string,
        owner_name: null,
        lease_template_id: number,
        status: string,
        penanlty_late_payment: boolean,
        custom_contract: boolean,
        contract_template: string,
        agreement_duration: string,
        created_at: string,
        updated_at: string,
        owner: DraftOwnerDetails | null,
        lease_template_pdf: {
            url: string
        },
        contract_template_pdf: {
            url: string
        },

        building_name: string,
        contract_type: null,
        expire_on: number,
        lease_template_text: string,
        society_management: DraftSocietyMangementDetails | null,
        custom_term_condition_added: boolean,
        terms: null,
        unit_name: string,
        conditions: null,
        custom_term_condition: null,
        tenant: {
            first_name: null,
            last_name: null,
            full_phone_number: string,
            country_code: null,
            phone_number: number,
            email: string,
            activated: boolean,
            device_id: null,
            id: number,
            password_digest: string,
            created_at: string,
            updated_at: string,
            user_name: null,
            platform: null,
            user_type: null,
            app_language_id: null,
            unique_auth_id: null,
            is_blacklisted: boolean,
            suspend_until: null,
            status: string,
            stripe_id: null,
            stripe_subscription_id: null,
            stripe_subscription_date: null,
            role_id: null,
            full_name: string,
            gender: null,
            date_of_birth: null,
            age: null,
            is_paid: boolean,
            language_type: null,
            company_name: null,
            manager_full_name: null,
            owner_full_name: null,
            last_visit_at: null,
            owner_phone_number: null,
            public_field: null,
            disable_chat: boolean,
            building_management_id: null,
            owner_email: null,
            ticket_days_configuration: number
        },
        custom_contract_image: null
        penanlty_late_payments: null,        
    }
}

export interface DraftOwnerDetails {
    first_name: null,
    last_name: null,
    full_phone_number: string,
    id: number,
    phone_number: number,
    email: string,
    activated: boolean,
    country_code: null,
    unique_auth_id: null,
    password_digest: string,
    created_at: string,
    updated_at: string,
    device_id: null,
    user_name: null,
    platform: null,
    user_type: null,
    app_language_id: null,
    last_visit_at: null,
    is_blacklisted: boolean,
    suspend_until: null,
    status: string,
    stripe_id: null,
    stripe_subscription_id: null,
    stripe_subscription_date: null,
    role_id: null,
    gender: string,
    date_of_birth: null,
    age: null,
    full_name: string,
    company_name: null,
    manager_full_name: null,
    owner_full_name: null,
    ticket_days_configuration: number
    owner_email: null,
    owner_phone_number: null,
    public_field: null,
    disable_chat: boolean,
    building_management_id: null,
    is_paid: boolean,
    language_type: null,
}

export interface DraftSocietyMangementDetails {
    name: string,
    description: string,
    created_at: string,
    updated_at: string,
    complex_area: string,
    maintenance_per_square_feet: number,
    id: number,
    ticket_days_configuration: number,
    contigency: number,
    zone_multiplier: number,
    measurement_unit: string,
    latitude: null,
    longitude: null,
    status: null
    late_charge_percentage: string,
    is_building: boolean,
    real_estate_company_id: null,
    total_floor: null,
    total_unit: null,
    value_added_tax: string,
    account_manager_id: null,
    language: null,
    currency_id: null,
}

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
    baseURL?: string;
};

export interface ContractSuccessResponse {
    message: string
};

export interface ContractListSuccessResponsewithData {
    code: number,
    contracts: {
        data: Array<ContractListItem>
    },
    meta: {
        pagination: {
            current_page: number,
            next_page: number,
            prev_page: null,
            total_pages: number,
            total_count: number
        }
    }
};
export interface SavedTemplateItem {
    id: string,
    type: string,
    attributes: {
        templates: number
        id: number,
        owner_name: string,
        unit: [
            {
                id: number,
                society_management_id: number,
                building_management_id: number,
                created_at: string,
                updated_at: string,
                apartment_name: string,
                floor_number: number,
                size: string,
                purchase_price: string,
                configuration: string,
                purchase_date: string,
                unit_type: null,
                status: string,
                current_valuation: string,
                monthly_renting_income: null,
                account_id: null,
                owner_name: null,
                resident_name: null
            }
        ],
        building: [
            {
                id: number,
                name: string,
                society_management_id: number,
                description: null,
                created_at: string,
                updated_at: string,
                per_floor_unit: number,
                generation_methods: string,
                number_of_floor: number,
                building_area: string,
                account_id: null,
                lat: null,
                long: null,
                city: null,
                country: null,
                googlemaplocation: null,
                real_estate_company_id: null
            }
        ],
        complex: Array<ComplexItem>,
    }
};

export interface ComplexItem {
    id: number,
    name: string,
    description: string,
    created_at: string,
    updated_at: string,
    complex_area: string,
    maintenance_per_square_feet: number,
    measurement_unit: string,
    ticket_days_configuration: number,
    contigency: number,
    zone_multiplier: number,
    late_charge_percentage: string,
    latitude: null,
    longitude: null,
    value_added_tax: string,
    is_building: boolean,
    real_estate_company_id: null,
    total_floor: null,
    total_unit: null,
    currency_id: null,
    account_manager_id: null,
    language: null,
    status: null
};
export interface GetsavedTemplatesSuccessResponse {
    account: {
        data: Array<SavedTemplateItem>
    },
    meta: {
        pagination: {
            current_page: number,
            next_page: null,
            prev_page: null,
            total_pages: number,
            total_count: number
        }
    }
}

export interface GetdraftsSuccessResponse {
    code: number,
    contracts: {
        data: Array<DraftListItem>
    },
    meta: {
        pagination: {
            current_page: number,
            next_page: null,
            prev_page: null,
            total_pages: number,
            total_count: number
        }
    }
};

export interface ApiFailureResponse {
    errors: Array<ApiFailureResponseError>
};

export interface SelectedComplexErrorResponse{
    errors: string;
}

export interface SavedTemplateError {
    message: string;
}

export interface ApiFailureResponseError {
    contract: string
};
export interface GetCountryListSuccessResponse {
    data: {
        countries: Array<string>;
    }
};

export interface GetCityListSuccessResponse {
    data: {
        cities: Array<string>
    }
}


export interface GetBuildingSuccessResponse {
    data: {
        buildings: Array<SingleBuilding>;
    };
};

export interface SingleBuilding {
    id: number;
    name: string;
    society_management_id: number;
    description: string | null;
    created_at: string;
    updated_at: string;
    per_floor_unit: number;
    generation_methods: string;
    number_of_floor: number;
    building_area: string;
    account_id: number | null;
    lat: number | null;
    long: number | null;
    city: string | null;
    country: string | null;
    googlemaplocation: string | null;
    real_estate_company_id: number | null;
};


interface ComplexData {
    message: string,
    complexes: {
        data: Array<SingleComplex>
    }
}

export interface SingleComplex {
    attributes: {
        name: string,
        description: string,
        complex_area: null,
        measurement_unit: null,
        maintenance_per_square_feet: null,
        is_building: null,
        logo: null,
        photos: [],
        is_building_list: null,
        shared_area: [],
        documents: [
            {
                id: number,
                created_at: string,
                updated_at: string,
                account_id: number,
                document_type: string,
                title: string,
                description: null,
                society_id: number,
                employee_account_id: null,
                all_society: boolean,
                society_ids: []
            }
        ],
        building_list: [
            {
                building_management_id: number,
                building_name: string
            }
        ],

        total_units: Array<number>,
        total_buildings: number,
        country: string,
        city: string,
        long: null,
        lat: null,
        complex_lat: null,
        currency: {
            id: number,
            currency: string,
            created_at: string,
            updated_at: string,
            currency_ar: null
        },
        complex_long: null,
        subscription_end_in: null,
        jurisdiction: null,
        paying_month: null,
        account_manager: null
        subscription_type: null,
    },
    id: string,
    type: string,
};

export interface UnitNumberList {
    data: {
        unit_apartments: Array<UnitNumberinterface>
    }
};

export interface UnitNumberinterface {
    id: number,
    society_management_id: number,
    building_management_id: number,
    created_at: string,
    updated_at: string,
    apartment_name: string,
    floor_number: number,
    size: null,
    purchase_price: string,
    configuration: null,
    purchase_date: null,
    unit_type: string,
    status: string,
    current_valuation: string,
    monthly_renting_income: string,
    account_id: null,
    owner_name: null,
    resident_name: null
};

export interface GetSelectComplexListResponseItem {
    id: string;
    type: string;
    attributes: {
        name: string;
        description: string;
        complex_area: string;
        measurement_unit: null;
        maintenance_per_square_feet: null;
        is_building: boolean;
        total_floor: null;
        total_unit: null;
        currency_id: null;
        account_manager_id: null;
        language: null;
        status: null;
        logo: {
            url: string;
            content_type: string;
            file_name: string
        };
        photos: [
            {
                url: string;
                content_type: string;
                file_name: string
            }
        ];
        subscription_end_in: null;
        subscription_id: null;
        is_building_list: null;
        building_list: [];
        shared_area: [
            {
                id: number;
                name: string;
                society_management_id: number;
                created_at: string;
                updated_at: string;
                details: string;
                total_area: string;
                currency_id: null;
                reservation_fee: number
            }
        ];
        documents: [];
        building_documents: [];
        incident_categories: {
            Plumbing: number;
            Carpenter: number;
            Electrician: number
        };
        total_buildings: number;
        active_users: null;
        inactive_users: null;
        pending_ownership_requests: number;
        collected_management_fee: null;
        company_name: null;
        rented: number;
        total_polls: number;
        last_date_of_polls: null;
        total_surveys: number;
        last_date_of_surveys: null;
        building_ownership_rate: null;
        ownership_onboarded: {
            ownership_percent: string;
            ownership_count: number
        };
        tenant_resquests: {
            tenant_percent: number;
            tenant_count: number
        };
        total_units: [];
        account_manager: null;
        sales_manager: null;
        country: string;
        city: string;
        lat: null;
        long: null;
        streetname: string;
        zipcode: string;
        landmark: string;
        locationlink: null;
        complex_lat: null;
        complex_long: null;
        currency: {
            id: number;
            currency: string;
            created_at: string;
            updated_at: string;
            currency_ar: string
        };
        subscription_type: null;
        plan_id: null;
        paying_month: null;
        googlemaplocation: null;
        jurisdiction: {
            data: {
                id: string;
                type: string;
                attributes: {
                    id: number;
                    jurisdiction_id: number;
                    jurisdiction_name: string;
                    currency: string;
                    country: string;
                    timezone: string;
                    unit_of_measurement: string;
                    mobile_number_length: string;
                    formula: string;
                    value_added_tax: number;
                    zone_multiplier: number;
                    complex: [
                        {
                            id: number;
                            name: string;
                            description: string;
                            created_at: string;
                            updated_at: string;
                            complex_area: string;
                            maintenance_per_square_feet: null;
                            measurement_unit: null;
                            ticket_days_configuration: number;
                            contigency: null;
                            zone_multiplier: null;
                            late_charge_percentage: null;
                            latitude: null;
                            longitude: null;
                            value_added_tax: null;
                            is_building: boolean;
                            real_estate_company_id: null;
                            total_floor: null;
                            total_unit: null;
                            currency_id: null;
                            account_manager_id: null;
                            language: null;
                            status: null
                        }
                    ];
                    total_complex: number;
                    total_buildings: number;
                    total_units: number;
                    contracts: []
                }
            }
        }
    }
};

export interface GetSelectComplexListResponse {
    data: Array<GetSelectComplexListResponseItem>;
};

export interface GetOwnerListSuccessResponseItem {
    first_name: null,
    id: number,
    full_phone_number: string,
    last_name: null,
    phone_number: number,
    country_code: null,
    activated: boolean,
    device_id: null,
    unique_auth_id: null,
    password_digest: string,
    email: string,
    updated_at: string,
    user_name: null,
    platform: null,
    created_at: string,
    app_language_id: null,
    last_visit_at: null,
    user_type: null,
    suspend_until: null,
    status: string,
    stripe_id: null,
    stripe_subscription_id: null,
    stripe_subscription_date: null,
    is_blacklisted: boolean,
    role_id: null,
    full_name: string,
    date_of_birth: null,
    age: null,
    is_paid: boolean,
    gender: null,
    company_name: null,
    manager_full_name: null,
    public_field: null,
    ticket_days_configuration: number
    owner_email: null,
    owner_phone_number: null,
    owner_full_name: null,
    disable_chat: string,
    building_management_id: null,
    language_type: null,
}

export interface GetOwnerListSuccessResponse {
    accounts: Array<GetOwnerListSuccessResponseItem>
}

export interface CompanyListSuccessResponse {
    data: Array<CompanyListSuccessResponseItem>;
};

export interface CompanyListSuccessResponseItem {
    id: string,
    type: string,
    attributes: {
        name: string,
        description: string,
        complex_area: string,
        measurement_unit: string,
        maintenance_per_square_feet: number,
        is_building: boolean,
        total_floor: null,
        total_unit: null,
        currency_id: null,
        account_manager_id: null,
        language: null,
        status: null,
        logo: {
            url: string,
            content_type: string,
            file_name: string
        },
        photos: [
            {
                url: string,
                content_type: string,
                file_name: string
            }
        ],
        subscription_end_in: string,
        subscription_id: number,
        is_building_list: null,
        building_list: [
            {
                building_management_id: number,
                building_name: string
            }
        ],
        shared_area: [
            {
                id: number,
                name: string,
                society_management_id: number,
                created_at: string,
                updated_at: string,
                details: string,
                total_area: string,
                currency_id: number,
                reservation_fee: number
            }
        ],
        documents: [],
        building_documents: [],
        incident_categories: {
            Plumbing: number,
            Carpenter: number,
            Electrician: number
        },
        total_buildings: number,
        active_users: null,
        inactive_users: null,
        pending_ownership_requests: number,
        collected_management_fee: number,
        company_name: null,
        rented: number,
        total_polls: number,
        last_date_of_polls: string,
        total_surveys: number,
        last_date_of_surveys: string,
        building_ownership_rate: number,
        ownership_onboarded: {
            ownership_percent: string,
            ownership_count: number
        },
        tenant_resquests: {
            tenant_percent: number,
            tenant_count: number
        },
        total_units: Array<number>,
        account_manager: null,
        sales_manager: null,
        country: string,
        city: string,
        lat: number,
        long: number,
        streetname: null,
        zipcode: null,
        landmark: null,
        locationlink: null,
        complex_lat: null,
        complex_long: null,
        currency: {
            id: number,
            currency: string,
            created_at: string,
            updated_at: string,
            currency_ar: string
        },
        subscription_type: string,
        plan_id: {
            id: number,
            title: string,
            description: string,
            created_at: string,
            updated_at: string
        },
        paying_month: string,
        googlemaplocation: null,
        jurisdiction: null
    }
}

export interface GetDeleteDraftResponse {
    code: number;
    message: string;
}

export interface ContractLisitingFailure {
    errors: [
        {
            contract: string;
            error: string;
        }
    ]
}

// Customizable Area End

interface SS {
    id: string;
}
export default class LeaseManagementMainController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    contractListingApiCallId: string = "";
    savedTemplatesApiCallId: string = "";
    draftsApiCallId: string = "";
    countryApiCallId: string = "";
    cityListApiCallId: string = "";
    buildingListApiCallId: string = "";
    unitNumberApiCallID: string = "";
    contractListingNextPageApiCallId: string = "";
    savedTemplateListingNextPageApiCallId: string = "";
    draftListingNextPageApiCallId: string = "";
    ownerListApiCallId: string = "";
    complexListApiCall: string = "";
    searchContractListApiCallId: string = "";
    companyListApiCallId: string = "";
    contractSortByApiCallId: string = "";
    savedTemplatesSearchApiCallId: string = "";
    deleteDraftApiCallId: string = "";
    contractSearchApiCallId: string = "";
    draftsSearchApiCallId: string = "";
        count : number = 1
        // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.NavigationMessageSendData),
            getName(MessageEnum.ContractDetailsId),
            getName(MessageEnum.DraftDetailsId),
        ];
        // Customizable Area End
        this.state = {
            // Customizable Area Start
                                    tabItem: "Contract Listing",
            complex: "",
            country: "",
            company: "",
            city: "",
            selectComplex: "",
            building: "",
            unitNumber: "",
            contractStatus: "",
            owner: "",
            contractIssuer: "",
            anchorEl: null,
            openPopoverIndex: null,
            contractListPage: 1,
            savedTemnplatePage: 1,
            draftListPage: 1,
            contractListData: [],
            contractSearchText: "",
            savedTemplatesListData: [],
            savedTemplateSearchText: "",
            draftListData: [],
            draftSearchText: "",
            countryList: [],
            cityList: [],
            buildingList: [],
            complexList: [],
            unitNumberList: [],
            contractListTotalItem: 0,
            contractListTotalPage: 0,
            savedTemplatesTotalItem: 0,
            savedTemplatesTotalPage: 0,
            draftListTotalItem: 0,
            draftListTotalPage: 0,
            selectComplexList: [],
            ownerList: [],
            companyList: [],
            showError: false,
            error: "",
            loader: false,
            societyMagementId: "",
            buildingManagementId: "",
            unitId: "",
            openBuildingModal: false,
            contractSortBy: "",
            deleteDraftModal: false,
            deleteDraftContractId: "",
            showSuccessMessage: false,
            successAlertMessage: "",
            latitude: null,
            longitude: null,
            contractPaginationData: {
                current_page: 0,
                next_page: 0,
                prev_page: 0,
                total_pages: 0,
                total_count: 0,
            },
            draftPaginationData: {
                current_page: 0,
                next_page: 0,
                prev_page: 0,
                total_pages: 0,
                total_count: 0,
            },
            savedTemplatePaginationData: {
                current_page: 0,
                next_page: 0,
                prev_page: 0,
                total_pages: 0,
                total_count: 0,
            },
            // Customizable Area End
        };
        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        this.getContractListing(0);
        this.getsavedTemplates();
        this.getdrafts();
        this.getCompanyListing();
        this.getCountryList();
        this.getOwnerList();
                // Customizable Area End
    }
    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if ((responseJson && !responseJson.errors) || responseJson.contracts) {
                this.apiResponseSucessCell(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.apiResponseFailureCall(apiRequestCallId, responseJson);
            }
        }
    };

    leaseManagmentMain = async (data: APIPayloadType) => {
        let { method, endPoint, body, type = "", contentType } = data;
        let token = localStorage.getItem("loginSuccessToken")
        const header = {
            token: token,
            "Content-Type": contentType,
        };
        let contractDetailsrequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== "formData"
            ? contractDetailsrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : contractDetailsrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(contractDetailsrequestMessage.id, contractDetailsrequestMessage);
        return contractDetailsrequestMessage.messageId;
    };

    apiResponseSucessCell = async (
        apiRequestCallId: string,
        responseJson: ContractListSuccessResponsewithData &
            GetsavedTemplatesSuccessResponse &
            GetdraftsSuccessResponse &
            GetCountryListSuccessResponse &
            GetCityListSuccessResponse &
            GetBuildingSuccessResponse &
            ComplexData &
            UnitNumberList &
            ContractSuccessResponse &
            GetSelectComplexListResponse &
            GetOwnerListSuccessResponse &
            CompanyListSuccessResponse
    ) => {
        if (apiRequestCallId === this.contractListingApiCallId) {
            this.contractListingSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.contractSortByApiCallId) {
            this.contractSortBySucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.contractSearchApiCallId) {
            this.contractSearchSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.savedTemplatesApiCallId) {
            this.savedTemplatesSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.savedTemplatesSearchApiCallId) {
            this.savedTemplatesSearchSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.draftsApiCallId) {
            this.draftsSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.draftsSearchApiCallId) {
            this.draftSearchSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.countryApiCallId) {
            this.getCountryListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityListApiCallId) {
            this.getCityListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.buildingListApiCallId) {
            this.getBuildingListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.unitNumberApiCallID) {
            this.getUnitNumberListSucessCallBack(responseJson);
        }
        this.leaseManagementApiResponseScccesCell(apiRequestCallId,responseJson);
    };

    leaseManagementApiResponseScccesCell = (apiRequestCallId: string, responseJson: ContractListSuccessResponsewithData &
        GetsavedTemplatesSuccessResponse &
        GetdraftsSuccessResponse &
        GetCountryListSuccessResponse &
        GetCityListSuccessResponse &
        GetBuildingSuccessResponse &
        ComplexData &
        UnitNumberList &
        ContractSuccessResponse &
        GetSelectComplexListResponse &
        GetOwnerListSuccessResponse &
        CompanyListSuccessResponse) => {
        if (apiRequestCallId === this.ownerListApiCallId) {
            this.ownerListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.deleteDraftApiCallId) {
            this.deleteDraftSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.complexListApiCall) {
            this.getComplexListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.companyListApiCallId) {
            this.companyListSucessCallBack(responseJson)
        }
            };

    apiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse & SavedTemplateError & SelectedComplexErrorResponse & ContractLisitingFailure) => {
        if (apiRequestCallId === this.contractListingApiCallId) {
            this.contractListingFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.contractSortByApiCallId) {
            this.contractSortByFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.contractSearchApiCallId) {
            this.contractSearchFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.savedTemplatesApiCallId) {
            this.savedTemplatesFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.savedTemplatesSearchApiCallId) {
            this.savedTemplatesSearchFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.draftsApiCallId) {
            this.draftsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.draftsSearchApiCallId) {
            this.draftSearchFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.countryApiCallId) {
            this.getCountryListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityListApiCallId) {
            this.getCityListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.buildingListApiCallId) {
            this.getBuildingListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.unitNumberApiCallID) {
            this.getUnitListNumberFailureCallBack(responseJson);
        }
        this.leaseManagementApiResponseFailureCall(apiRequestCallId, responseJson)
    };

    leaseManagementApiResponseFailureCall = (apiRequestCallId: string, responseJson: ApiFailureResponse & SavedTemplateError & SelectedComplexErrorResponse) => {
        if (apiRequestCallId === this.ownerListApiCallId) {
            this.ownerListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.deleteDraftApiCallId) {
            this.deleteDraftFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.complexListApiCall) {
            this.getComplexListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.companyListApiCallId) {
            this.companyListFailureCallBack(responseJson)
        }
            };

    getContractListing = async (page: number) => {
        this.setState({ loader: true });
        const { societyMagementId, country, city, buildingManagementId, contractStatus, contractIssuer, unitId, owner, contractSortBy } = this.state;
        let searchContractListEndPoint = `${configJSON.contractListingEndPoint}page=${page}`;

        if (country) {
            searchContractListEndPoint += `&country=${country}`;
        }

        if (city) {
            searchContractListEndPoint += `&city=${city}`;
        }

        if (societyMagementId) {
            searchContractListEndPoint += `&complex_id=${societyMagementId}`;
        }

        if (buildingManagementId) {
            searchContractListEndPoint += `&building_management_id=${buildingManagementId}`;
        }

        if (unitId) {
            searchContractListEndPoint += `&apartment_management_id=${unitId}`;
        }

        if (contractStatus) {
            searchContractListEndPoint += `&status=${contractStatus}`;
        }
        if (contractIssuer) {
            searchContractListEndPoint += `&issuer=${contractIssuer}`;
        }
        if (owner) {
            searchContractListEndPoint += `&search=${owner}`;
        }
        if (contractSortBy) {
            searchContractListEndPoint += `&sort_by=${contractSortBy}`;
        }
        this.contractListingApiCallId = await this.leaseManagmentMain({
            method: configJSON.contractListingMethod,
            endPoint: searchContractListEndPoint,
        });
    };

    contractListingSucessCallBack = (response: ContractListSuccessResponsewithData) => {

        this.setState({ contractListData: response.contracts.data, loader: false }, () => {
            if (response.meta.pagination !== null) {
                this.setState({
                    contractPaginationData: response.meta.pagination
                });
            } else {
                this.setState({
                    contractPaginationData: {
                        current_page: 0,
                        next_page: 0,
                        prev_page: 0,
                        total_pages: 0,
                        total_count: 0,
                    }
                });
            }
        });
    };

    contractListingFailureCallBack = (response: ApiFailureResponse & ContractLisitingFailure) => {
        if (response.errors[0].contract) {
            this.setState({
                showError: true,
                error: response.errors[0].contract,
                loader: false,
                contractListData: [],
                contractListTotalItem: 0,
                contractListTotalPage: 0,
            });
        } else if (response.errors[0].error) {
            this.setState({
                showError: true,
                error: response.errors[0].error,
                loader: false,
                contractListData: [],
                contractListTotalItem: 0,
                contractListTotalPage: 0,
            });
        }
    };

    getContractSortBy = async (sortByOption: string) => {
        this.setState({ loader: true });
        this.contractSortByApiCallId = await this.leaseManagmentMain({
            method: configJSON.contractListingMethod,
            endPoint: `${configJSON.contractListSortEndPoint}${sortByOption}`,
        });
    };

    contractSortBySucessCallBack = (response: ContractListSuccessResponsewithData) => {
        this.setState({ contractListData: response.contracts.data, loader: false }, () => {
            if (response.meta.pagination !== null) {
                this.setState({
                    contractPaginationData: response.meta.pagination,
                    contractListPage: response.meta.pagination.current_page
                });
            } else {
                this.setState({
                    contractPaginationData: {
                        current_page: 0,
                        next_page: 0,
                        prev_page: 0,
                        total_pages: 0,
                        total_count: 0,
                    }
                });
            }
        });
    };

    contractSortByFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract,
            contractListData: [],
            contractListTotalItem: 0,
            contractListTotalPage: 0,
            loader: false 
        });
    };

    getContractSearch = async (searchText: string) => {
        this.setState({ loader: true });
        this.contractSearchApiCallId = await this.leaseManagmentMain({
            method: configJSON.contractListingMethod,
            endPoint: `${configJSON.contractListSearchEndPoint}${searchText}`,
        });
    };

    contractSearchSucessCallBack = (response: ContractListSuccessResponsewithData) => {
        this.setState({ contractListData: response.contracts.data, loader: false }, () => {
            if (response.meta.pagination !== null) {
                this.setState({
                    contractPaginationData: response.meta.pagination
                });
            } else {
                this.setState({
                    contractPaginationData: {
                        current_page: 0,
                        next_page: 0,
                        prev_page: 0,
                        total_pages: 0,
                        total_count: 0,
                    }
                });
            }
        });
    };

    contractSearchFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };

    searchListData = () => {
        if (this.state.tabItem === "Contract Listing") {
            this.getContractListing(1);
        } else if (this.state.tabItem === "Saved Templates") {
            this.getsavedTemplates();
        } else if (this.state.tabItem === "Drafts") {
            this.getdrafts();
        }
    };

    getCompanyListing = async () => {
        this.companyListApiCallId = await this.leaseManagmentMain({
            method: configJSON.contractListingMethod,
            endPoint: configJSON.companyListEndpoint,
        });
    };

    companyListSucessCallBack = (response: CompanyListSuccessResponse) => {
        this.setState({ companyList: response.data })
    };

    companyListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };

    getsavedTemplates = async () => {
        this.setState({ loader: true });
        const { country, city, buildingManagementId,complex, contractStatus, unitId, societyMagementId } = this.state;
        let searchSaveTemplatesEndPointEndPoint = `${configJSON.saveTemplatesEndPoint}page=${this.state.savedTemnplatePage}&per_page=10`;
        if (country) {
            searchSaveTemplatesEndPointEndPoint += `&country=${country}`;
        }
        if (city) {
            searchSaveTemplatesEndPointEndPoint += `&city=${city}`;
        }
        if (societyMagementId) {
            searchSaveTemplatesEndPointEndPoint += `&complex=${societyMagementId}`;
        }

        if (buildingManagementId) {
            searchSaveTemplatesEndPointEndPoint += `&building=${buildingManagementId}`;
        }

        if (unitId) {
            searchSaveTemplatesEndPointEndPoint += `&unit=${unitId}`;
        }

        if (contractStatus) {
            searchSaveTemplatesEndPointEndPoint += `&status=${contractStatus}`;
        }
        if (complex === "Complex") {
            searchSaveTemplatesEndPointEndPoint += `&is_building=false`;
        }else{
            searchSaveTemplatesEndPointEndPoint += `&is_building=true`
        }
        this.savedTemplatesApiCallId = await this.leaseManagmentMain({
            method: configJSON.saveTemplatesMethod,
            endPoint: searchSaveTemplatesEndPointEndPoint,
        });
    };

    savedTemplatesSucessCallBack = (response: GetsavedTemplatesSuccessResponse & SavedTemplateError) => { 
        if(response.account.data){
            if (response.meta.pagination !== null && response.account.data.length>0) {
                this.setState({
                    savedTemplatesListData: response.account.data,
                    loader: false,
                    savedTemplatePaginationData: response.meta.pagination
                });
            } else {
                this.setState({
                    savedTemplatesListData:[],
                    savedTemplatePaginationData: {
                        current_page: 0,
                        next_page: 0,
                        prev_page: 0,
                        total_pages: 0,
                        total_count: 0,
                    },
                    loader: false,
                });
            }
        }  if (response.message) {
            this.setState({
                showError: true,
                error: response.message,
                savedTemplatesListData: [],
                savedTemplatesTotalItem: 0,
                savedTemplatesTotalPage: 0,
                loader: false
            });            
        }
    };

    savedTemplatesFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };

    getSavedTemplatesSearch = async (searchText: string) => {
        this.setState({ loader: true });
        this.savedTemplatesSearchApiCallId = await this.leaseManagmentMain({
            method: configJSON.saveTemplatesMethod,
            endPoint: `${configJSON.searchSavedTemplateTextEndPoint}${searchText}`,
        });
    };

    savedTemplatesSearchSucessCallBack = (response: GetsavedTemplatesSuccessResponse & SavedTemplateError) => {
        if (response.account.data) {
            if (response.meta.pagination !== null) {
                this.setState({
                    savedTemplatesListData: response.account.data,
                    loader: false,
                    savedTemplatePaginationData: response.meta.pagination
                });
            } else {
                this.setState({
                    savedTemplatesListData: [],
                    savedTemplatePaginationData: {
                        current_page: 0,
                        next_page: 0,
                        prev_page: 0,
                        total_pages: 0,
                        total_count: 0,
                    },
                    loader: false,
                });
            }
        } else if (response.message) {
            this.setState({
                showError: true,
                error: response.message,
                savedTemplatesListData: [],
                loader: false,
                savedTemplatesTotalItem: 0,
                savedTemplatesTotalPage: 0,
            });
        }
    };

    savedTemplatesSearchFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };

    getdrafts = async () => {
        this.setState({loader:true});
        const { country, city, buildingManagementId, contractStatus, unitId } = this.state;
        let searchDraftListEndPoint = `${configJSON.draftListEndPoint}page=${this.state.draftListPage}`;
        if (country) {
            searchDraftListEndPoint += `&country=${country}`;
        }
        if (city) {
            searchDraftListEndPoint += `&city=${city}`;
        }
        if (country) {
            searchDraftListEndPoint += `&country=${country}`;
        }

        if (buildingManagementId) {
            searchDraftListEndPoint += `&building_management_id=${buildingManagementId}`;
        }

        if (unitId) {
            searchDraftListEndPoint += `&apartment_management_id=${unitId}`;
        }

        if (contractStatus) {
            searchDraftListEndPoint += `&status=${contractStatus}`;
        }
        this.draftsApiCallId = await this.leaseManagmentMain({
            method: configJSON.draftListMethod,
            endPoint: searchDraftListEndPoint
        });
    };

    draftsSucessCallBack = (response: GetdraftsSuccessResponse) => {
                this.setState({ draftListData: response.contracts.data }, () => {
            if (response.meta.pagination !== null) {
                this.setState({ draftPaginationData: response.meta.pagination, loader: false });
            } else {
                this.setState({
                    draftPaginationData: {
                        current_page: 0,
                        next_page: 0,
                        prev_page: 0,
                        total_pages: 0,
                        total_count: 0,
                    }, 
                    loader: false
                });
            }
        });
    };

    draftsFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract,loader:false });
    };

    getDraftSearch = async (searchText: string) => {
        this.draftsSearchApiCallId = await this.leaseManagmentMain({
            endPoint: `${configJSON.draftSearchEndPoint}${searchText}`,
            method: configJSON.draftListMethod,
        });
    };


    draftSearchFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };
    

    draftSearchSucessCallBack = (response: GetdraftsSuccessResponse) => {
        this.setState({ draftListData: response.contracts.data, loader: false }, () => {
            if (response.meta.pagination !== null) {
                this.setState({ draftPaginationData: response.meta.pagination });
            } else {
                this.setState({
                    draftPaginationData: {
                        current_page: 0,
                        next_page: 0,
                        prev_page: 0,
                        total_pages: 0,
                        total_count: 0,
                    }
                });
            }
        });
    };

    getCountryList = async () => {
        this.countryApiCallId = await this.leaseManagmentMain({
            method: configJSON.countryListMethod,
            endPoint: configJSON.countryEndPoint,
        });
    };

    getCountryListSucessCallBack = (response: GetCountryListSuccessResponse) => {
        this.setState({ countryList: response.data.countries })
    };

    getCountryListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };

    getCityList = async () => {
        this.cityListApiCallId = await this.leaseManagmentMain({
            method: configJSON.cityListMethod,
            endPoint: `${configJSON.cityEndPoint}${this.state.country}`,
        });
    };

    getCityListSucessCallBack = (response: GetCityListSuccessResponse) => {
        this.setState({ cityList: response.data.cities })
    };

    getCityListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };

    getComplexList = async (complex: boolean) => {
        this.complexListApiCall = await this.leaseManagmentMain({
            method: configJSON.complexListMethod,
            endPoint: `${configJSON.complexSelectListEndPoint}?city=${this.state.city}&is_building=${complex}`,
        });
    };

    getComplexListSucessCallBack = (response: GetSelectComplexListResponse) => {
        this.setState({ selectComplexList: response.data })
    };

    getComplexListFailureCallBack = (response: ApiFailureResponse & SelectedComplexErrorResponse) => {
        if(response.errors){
            this.setState({ showError: false, error: response.errors });
        }else if( response.errors[0].contract){
            this.setState({ showError: false, error: response.errors[0].contract });
        }
    };

    getBuildingList = async () => {
        this.buildingListApiCallId = await this.leaseManagmentMain({
            method: configJSON.buildingListMethod,
            endPoint: `${configJSON.buildingListEndPoint}${this.state.societyMagementId}`,
        });
    };

    getBuildingListSucessCallBack = (response: GetBuildingSuccessResponse) => {
        this.setState({ buildingList: response.data.buildings,buildingManagementId: response.data.buildings[0].id + "" },()=>{
            if(this.state.complex === "Individual Building"){
                this.getUnitNumberList()
            }
        }
        )
    };

    getBuildingListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };

    getUnitNumberList = async () => {
        this.unitNumberApiCallID = await this.leaseManagmentMain({
            method: configJSON.unitNumberListMethod,
            endPoint: `${configJSON.unitNumberListEndPoint}${this.state.buildingManagementId}`,
        });
    };

    getUnitNumberListSucessCallBack = (response: UnitNumberList) => {
        this.setState({ unitNumberList: response.data.unit_apartments })
    };

    getUnitListNumberFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };

    getOwnerList = async () => {
        this.ownerListApiCallId = await this.leaseManagmentMain({
            method: configJSON.onwerListMethod,
            endPoint: `${configJSON.ownerListEndPoint}`,
        });
    };

    ownerListSucessCallBack = (response: GetOwnerListSuccessResponse) => {
                this.setState({ ownerList: response.accounts })
    };

    ownerListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };

    deleteDraftApiCall = async () => {
        this.deleteDraftApiCallId = await this.leaseManagmentMain({
            method: configJSON.deleteDraftContractDetailsMethod,
            endPoint: `${configJSON.deleteDraftEndpoint}${this.state.deleteDraftContractId}?status=Draft`,
        });
    };

    deleteDraftSucessCallBack = (response: GetDeleteDraftResponse) => {
        this.setState({ showSuccessMessage: true, successAlertMessage: response.message, deleteDraftModal: false });
    };

    deleteDraftFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };

    handleContractTab = () => this.setState({
        tabItem: "Contract Listing",
        complex: "",
        country: "",
        city: "",
        selectComplex: "",
        building: "",
        unitNumber: "",
        contractIssuer: "",
        owner: "",
        contractStatus: ""
    });

    handleSavedTemplate = () => {
        this.setState({
            tabItem: "Saved Templates",
            complex: "",
            country: "",
            city: "",
            selectComplex: "",
            building: "",
            unitNumber: "",
            contractIssuer: "",
            owner: "",
            contractStatus: ""
        });
    };

    handleDraft = () => {
        this.setState({
            tabItem: "Drafts",
            complex: "",
            country: "",
            city: "",
            selectComplex: "",
            building: "",
            unitNumber: "",
            contractIssuer: "",
            owner: "",
            contractStatus: ""
        });
    };

    handleClose = () => {
        this.setState({ anchorEl: null, openPopoverIndex: null });
    };

    handleClick = (event: MouseEvent<HTMLElement>, index: number) => {
        this.setState({ anchorEl: event.currentTarget, openPopoverIndex: index });
    };

    handleViewContract = async (contractId: string) => {
        await setStorageData('contractId',contractId);
                const msgData: Message = new Message(
            getName(MessageEnum.NavigationMessageSendData)
        );
        msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "ContractDetails");
        msgData.addData(getName(MessageEnum.ContractDetailsId), contractId);
        this.send(msgData);
    };

    handleViewDraft = async(path: string, draftId: string | number) => { 
        await setStorageData("savedTemplateId",draftId)       
        await setStorageData("complexType",this.state.complex)
        const msgData: Message = new Message(
            getName(MessageEnum.NavigationMessageSendData)
        );
        msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
        msgData.addData(getName(MessageEnum.DraftDetailsId), draftId);
        this.send(msgData);
    };

    handleContractListPagination = (event: React.ChangeEvent<unknown>, value: number) => {
        const newPageCount = Number(value);
        this.setState({ contractListPage: newPageCount }, () => {
            this.getContractListing(newPageCount);
        });
    };

    handleSavedTemplateListPagination = (event: React.ChangeEvent<unknown>, value: number) => {
        const newPageCount = Number(value);
        this.setState({ savedTemnplatePage: newPageCount }, () => {
            this.getsavedTemplates();
        });
    };

    handleDraftListPagination = (event: React.ChangeEvent<unknown>, value: number) => {
        const newPageCount = Number(value);
        this.setState({ draftListPage: newPageCount }, () => {
            this.getdrafts();
        });
    };

    hanldeCompanyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ company: event.target.value as string });
    };

    hanldeComplexChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ complex: event.target.value as string, selectComplex: "", selectComplexList: [], building: "",buildingList: [] }, () => {
            if (this.state.complex === "Complex") {
                this.getComplexList(false);
            } else if (this.state.complex === "Individual Building") {
                this.getComplexList(true);
            }
        });
    };

    hanldeCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ country: event.target.value as string,city:"",cityList:[] }, () => {
            this.getCityList();
        });
    };

    hanldeCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ city: event.target.value as string,selectComplex:"",selectComplexList:[] },()=>{
            if (this.state.complex === "Complex") {
                this.getComplexList(false);
            } else if (this.state.complex === "Individual Building") {
                this.getComplexList(true);
            }
        });
    };

    hanldeSelectComplexChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { selectComplexList } = this.state;
        const selectedComplex = event.target.value as string;
        const selectedComplexData = selectComplexList.find((item) => item.attributes.name === selectedComplex);
        if (selectedComplexData) {
            const societyManagementId = selectedComplexData.id + "";
            this.setState({ selectComplex: selectedComplex, societyMagementId: societyManagementId, building:"",buildingList:[],unitNumber:"",unitNumberList:[] }, () => {
                this.getBuildingList();
            });
        }
    };

    hanldeBuildingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { buildingList } = this.state;
        const selectedBuildingName = event.target.value as string;
        const selectedBuildingData = buildingList.find((item) => item.name === selectedBuildingName);        
        if (selectedBuildingData) {
            const buildingManagementId = String(selectedBuildingData.id);
            this.setState({ building: selectedBuildingName, buildingManagementId: buildingManagementId, unitNumber: "", unitNumberList: [] }, () => {
                this.getUnitNumberList();
            });
        }
    };

    hanldeUnitChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let unitItem = this.state.unitNumberList.find((item) => item.apartment_name === event.target.value)
        if (unitItem) {
            let unitId = String(unitItem.id);
            this.setState({ unitNumber: event.target.value as string, unitId: unitId });
        }
    };

    hanldeContractStatusChange = (event: any) => {
        this.setState({ contractStatus: event.target.value as string });
    };

    hanldeOwnerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ owner: event.target.value as string });
    };

    hanldeContractIssuerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ contractIssuer: event.target.value as string });
    };

    hanldeContractSortByChange = (event: any) => {
        this.setState({ contractSortBy: event.target.value as string,contractListPage: 0 }, () => {
            this.getContractSortBy(this.state.contractSortBy)
        })
    }

    handleDraftSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ draftSearchText: event.target.value }, () => {
            this.getDraftSearch(this.state.draftSearchText);
        });
    };

    handleSavedTemplateSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ savedTemplateSearchText: event.target.value }, () => {
            this.getSavedTemplatesSearch(this.state.savedTemplateSearchText);
        });
    };

    handleContractSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ contractSearchText: event.target.value }, () => {
            this.getContractSearch(this.state.contractSearchText);
        });
    };

    handleCloseAlertError = () => { this.setState({ showError: false, error: "" }); };

    handleNullValue = (value: string | boolean | number | undefined | null) => {
        if (value === null || value === "") {
            return "-"
        } else {
            return value
        }
    };

    handleNullObject = (value: SocietyManagement | null) => {
        return value ? value.name : "-";
    };

    handleIssueNewLeaseRedirect = () => {
        const msgData: Message = new Message(
            getName(MessageEnum.NavigationMessageSendData)
        );
        msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "IssueNewLease");
        this.send(msgData);
    };

    handleBuildingModal = (contractItem: ContractListItem) => {
        this.setState({
            openBuildingModal: !this.state.openBuildingModal,
            anchorEl: null,
            latitude: contractItem.attributes.society_management?.latitude,
            longitude: contractItem.attributes.society_management?.longitude,
            openPopoverIndex: null
        });
    };

    handleCloseViewBuilding = () => {
        this.setState({ openBuildingModal: !this.state.openBuildingModal });
    };

    handleDeleteDraftModal = (deleteDraftContractId: string) => {
        this.setState({ deleteDraftModal: !this.state.deleteDraftModal, deleteDraftContractId: deleteDraftContractId, anchorEl: null, openPopoverIndex: null });
    };

    handleSuccessAlert = () => {
        this.setState({ showSuccessMessage: false });
    };

    handleOwnerNullValue = (owner: null | DraftOwnerDetails) => {
        if (owner === null) {
            return "-";
        } else {
            return owner.full_name;
        }
    };

    handleSocietyNullValue = (society: null | DraftSocietyMangementDetails) => {
        if (society === null) {
            return "-";
        } else {
            return society.name;
        }
    }

    // Customizable Area End
}